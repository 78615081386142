export const normalizeDate = (date) => {
  const newDate = new Date(date * 1000);
  let day = newDate.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  let month = newDate.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  const year = newDate.getFullYear();

  return day + "." + month + "." + year;
};
